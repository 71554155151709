<template>
  <b-modal
    id="new-campaign-package-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ $t('create_a_strategy') }}</span>
      </h2>
    </div>
    <div class="text-center mt-3">
      <p>{{ $t('you_re_almost_there') }}</p>
      <p>{{ $t('last_step_create_a_targeting_strategy') }}</p>
      <p>{{ $t('to_be_taken_to_the_creation_page') }}</p>
      <router-link
        class="btn btn-primary mt-4"
        :to="{ name: 'lines.create', params: { cId: campaign ? campaign.id : '', pId: segmentation ? segmentation.id : '' } }"
      >
        {{ $t('create_a_strategy2') }}
      </router-link>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'NewCampaignPackageModal',
  props: ['campaign', 'segmentation']
}
</script>
