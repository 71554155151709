<template>
  <b-modal
    id="pause-campaign-package-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ $t('pause_group') }}</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="text-center mt-3">
      <div class="mb-4">
        <i class="fa fa-warning font-size-large"></i>
      </div>
      <p v-html="$t('are_you_want_to_pause_campaign_group', {name:this.segmentation.name})"></p>
      <p>{{ $t('deactivation_of_all_strategies_in_this_group') }}</p>
      <div class="mt-3">
        <!-- MESSAGES -->
        <div
          class="mb-2 alert-message-card text-color2 font-size-small px-3 py-2"
          v-if="loader"
        >
          <p
            class="mb-0"
          >
            {{ $t('this_action_may_take_a_few_minutes') }}
          </p>
        </div> <!-- END MESSAGES -->
        <Button
          type="button"
          class="btn btn-primary"
          @click="submit"
          :loading="loader"
        >
          {{ $t('pause_group') }}
        </Button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import CampaignPackagesService from '@/modules/campaigns/services/campaign-packages-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'PauseCampaignPackageModal',
  props: ['campaign', 'segmentation'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      loader: false,
      content: null,
      content_type: 'error'
    }
  },
  methods: {
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      try {
        await CampaignPackagesService.pauseCampaignPackage(this.segmentation.id)
        const notification = {
          notification: {
            type: 'success',
            content: this.$t('campaign_group_successfully_paused')
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$bvModal.hide('pause-campaign-package-modal')
        this.$emit('package-paused', this.campaign)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
